import React, {useEffect} from 'react';

import SignatureDisplay from './SignatureDisplay'
import { Card, CardBody} from 'reactstrap';
const SignatureContainer= (props) =>{

    useEffect(() => {
        const script = document.createElement("script")
    
        script.async = false

        script.innerHTML = ` var uploadField = document.getElementById("Logo");
        if (uploadField) {
            console.log('here1')
            uploadField.onchange = function() {
                console.log(this.files[0].size, 'here2')
                if(this.files[0].size > 1000000){
                   alert("File is too big!");
                   this.value = "";
                };
            };
        }`
    
        document.body.appendChild(script)
    
        return () => {
          document.body.removeChild(script)
        }
      }, [])
   
    console.log(props.list)
    return(
            <div >
                
                <Card  className="emailcard mb-2 mt-xs-5 ">
                    <CardBody className=" rounded p-0">
                            <div className="d-flex p-2 newmessagebar" >
                                <div className="pl-2 mr-auto text-white"><strong>New Message</strong></div>
                                <div className="text-white">
                                    <img src={require('../../img/x.svg')} />
                                </div>
                            </div>
                            <div className="mb-2 pl-2 pt-2 text-muted border-muted border-bottom">To:</div>
                            <div className="border-muted pt-1 pl-2 mb-3 text-muted border-bottom">Subject:</div>
                            <p className='px-2 font-italic mt-4'>Dear Mr. User</p>
                            <p className='px-2 font-italic'><strong>This is the preview of your real signature.</strong><br/>
                            Fill up your details and signature design in the column on the left.</p>
                            <p className='px-2 font-italic mb-3'>Best Regards,<br/> Ron Fybish</p>
                            {
                            props.isChange?
                                <div className="px-2"><SignatureDisplay list={props.list} /><br/></div>
                                :
                               
                                <table cellSpacing="0" cellPadding="0" border="0" style={{width: '600px', fontSize: '11pt', fontFamily: 'Arial, sans-serif' }}>
                                <tbody>
                                    <tr>
                                        <td width="104" style={{width: '104px', paddingTop: '3px', verticalAlign: 'top'}}>
                                            <a href="https://helpware.com" rel="noopener noreferrer" target="_blank">
                                                <img src={require('../../img/imgplaceholder.jpg')} alt="Banner" width="120" border="0" style={{width: '120px', height: 'auto', border:'0px', borderRadius: '10px'}}/>
                                            </a>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td width="474" style={{width: '450px', paddingLeft: '20px'}}>
                                            <table cellSpacing="0" cellPadding="0" style={{width: '450px', borderBottom: '2px solid #47c2b4', marginBottom: '10px', verticalAlign: 'bottom'}}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{verticalAlign: 'top'}}>
                                                            <span style={{fontFamily: 'Arial, sans-serif', fontWeight: 'bold', fontSize: '12pt', lineHeight: '23px', color: '#373838'}}> Your Name</span>
                                                        </td>
                                                        <td style={{textAlign: 'right'}}>
                                                            <img style={{height: '24px', width: '88px'}} height="24" width="88" src="https://siggen-content.s3.amazonaws.com/signatureicons/logoupscaled.png" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{paddingBottom: '7px'}}>
                                                            <span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(71, 194, 180)', fontWeight: 'bold', fontSize: '12pt', lineHeight: '23px', paddingTop: 7, display: 'inline-block' }}>Your job title
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                        
                                            <table cellSpacing="0" cellPadding="0" style={{width: '450px'}}>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <p style={{marginTop: 0, marginBottom: 7 }}>
                                                                <span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(71, 194, 180)', fontWeight: 'bold', fontSize: '12pt', lineHeight: '23px'}}>W</span>
                                                                <a href="https://helpware.com" rel="noopener noreferrer" target="_blank" style={{fontFamily: 'Arial, sans-serif',fontSize: '11pt', lineHeight: '23px', color: '#373838', textDecoration: 'none'}}>&nbsp;&nbsp;www.helpware.com</a>
                                                            </p>
                                                        </td>
                                                        <td>&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p style={{ marginBottom: 7 }}>
                                                                <span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(71, 194, 180)', fontWeight: 'bold', fontSize: '12pt', lineHeight: '23px'}}>E</span>
                                                                <a href="mailto:hello@helpware.com" style={{fontFamily: 'Arial, sans-serif',fontSize: '11pt', lineHeight: '23px', color: '#373838', paddingLeft: 4, textDecoration: 'none'}}>&nbsp;&nbsp;hello@helpware.com </a>
                                                            </p>
                                                        </td>
                                                        <td>
                                                          
                                                                    
                                                        <p style={{textAlign: 'right', marginBottom: 7}}>
                                                                            <span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(71, 194, 180)', fontWeight: 'bold', fontSize: '12pt', lineHeight: '23px'}}>T</span>
                                                                            <a href="tel:+1 (949) 273 - 2824" style={{fontFamily: 'Arial, sans-serif',fontSize: '11pt', lineHeight: '23px', color: '#373838', textDecoration: 'none'}}>&nbsp;&nbsp;+1 (949) 273 - 2824</a>
                                                                            <a href="https://www.linkedin.com/company/helpware/" rel="noopener noreferrer" target="_blank" style={{paddingLeft: 30}}><img style={{height: '17px', width: '17px'}} height="17" width="17" src="https://3358429.fs1.hubspotusercontent-na1.net/hubfs/3358429/linkedin.png" alt="linkedin" /></a>
                                                                        </p>
                                                                     
                                                                    
                                                                
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{paddingTop: 10}}><a href="#" rel="noopener noreferrer" target="_blank" style={{marginTop: '0px', backgroundColor: 'rgb(71, 194, 180)', borderRadius: '20px', color: 'rgb(255, 255, 255)', display: 'inline-block', fontFamily: 'sans-serif', fontSize: '13px', fontWeight: 'bold', lineHeight: '40px', textAlign: 'center', textDecoration: 'none', width: '120px'}}>book a meeting</a></td>
                                        <td style={{paddingTop: 10}}>
                                            <p style={{marginBottom: '0px', textAlign: 'right', paddingTop: 0}}><span style={{display: 'inline-block', height: '24px'}}><span><a href="https://clutch.co/profile/helpware#summary" rel="noopener noreferrer" target="_blank"><img alt="Facebook icon" src="https://siggen-content.s3.amazonaws.com/signatureicons/clutch.png" border="0" style={{border: '0px', height: '15px'}} height="15"/></a>&nbsp;&nbsp;</span><span><a href="https://www.g2.com/products/helpware/reviews" rel="noopener noreferrer" target="_blank"><img alt="LinkedIn icon" src="https://siggen-content.s3.amazonaws.com/signatureicons/upscaled.png" border="0" style={{border: '0px', height: '15px'}} height="15"/></a>&nbsp;&nbsp;</span><span><a href="https://www.goodfirms.co/company/helpware" rel="noopener noreferrer" target="_blank"><img alt="Twitter icon" src="https://siggen-content.s3.amazonaws.com/signatureicons/union.png" border="0" style={{border: '0px', height: '15px'}} height="15"/></a>&nbsp;&nbsp;</span><span><a href="https://upcity.com/profiles/helpware-io" rel="noopener noreferrer" target="_blank"><img alt="Youtube icon" src="https://siggen-content.s3.amazonaws.com/signatureicons/upcity.png" border="0" style={{border: '0px', height: '15px'}} height="15"/></a>&nbsp;&nbsp;</span><span><a href="https://upfirms.com/company/us/helpware/" rel="noopener noreferrer" target="_blank"><img alt="Instagram icon" src="https://siggen-content.s3.amazonaws.com/signatureicons/upfirms.png" border="0" style={{border: '0px', height: '15px'}} height="15"/></a>&nbsp;&nbsp;</span><span><a href="https://www.glassdoor.com/Reviews/Helpware-Reviews-E2089241.htm" rel="noopener noreferrer" target="_blank"><img alt="Instagram icon" src="https://siggen-content.s3.amazonaws.com/signatureicons/glassdoor.png" border="0" style={{border: '0px', height: '15px'}} height="15"/></a>&nbsp;&nbsp;</span></span></p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table> 
                               
                            }
                    </CardBody>
                </Card>
                   
            </div>
    )
}

export default SignatureContainer;

