import React, { Component } from 'react'
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink,} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom'

const AppNavBar = withRouter(props => <MyComponent {...props}/>);

export default AppNavBar;

class MyComponent extends Component {   
  state = {
    btn1: true,
  }

  // componentDidMount() {
  //   const s = document.createElement('script');
  //   s.type = 'text/javascript';
  //   s.async = true;
  //   s.innerHTML = `        var uploadField = document.querySelectorAll(".custom-file-input");

  //   uploadField.forEach(item => {
  //           if (item) {
  //           console.log('here1')
  //           item.onchange = function() {
  //               console.log(this.files[0].size, 'here2')
  //               if(this.files[0].size > 1000000){
  //                  alert("File is too big! Upload file less than 1mb in size");
  //                  this.value = "";
  //               };
  //           };
  //       }
  //   })`;
  //   document.body.appendChild(s);
  // }

  // componentWillUnmount(){
  //   document.body.removeChild(s)
  // }

  handleClick = () => {
      this.setState({btn1: !this.state.btn1})
  }
  
    render() {
      const {pathname} = this.props.location;
      console.log(pathname)
        return (
        <div >
            <Navbar className="mb-3" light>
              <NavbarBrand href="/" >
                      <img alt="logo-img" src={require('../img/hwlogo.svg')} />
                      
              </NavbarBrand>
              {/* <NavbarToggler onClick={this.props.toggle}  />
              <Collapse isOpen={this.props.isOpen} navbar>
                <Nav className="text-center" navbar>
                  <NavItem>
                    <Link to="/guide"><div className='my-2'><strong>User Guide</strong></div></Link>
                  </NavItem>
                  <NavItem>
                    <Link to="/signature-1"><div className='my-2'><strong>signature-1</strong></div></Link>
                  </NavItem>
                  <NavItem>
                    <Link to="/signature-2"><div className='my-2'><strong>signature-2</strong></div></Link>
                  </NavItem>
                  <NavItem>
                    <Link to="/signature-3"><div className='my-2'><strong>signature-3</strong></div></Link>
                  </NavItem>
                  <NavItem>
                    <Link to="/signature-4"><div className='my-2'><strong>signature-4</strong></div></Link>
                  </NavItem>
                </Nav>
              </Collapse> */}
                <Nav className={` text-center`} navbar>
                  {pathname.includes('guide') ? (
                               <NavItem>
                               <Link to="/" className={this.state.btn1 ? 'active' : ''}><strong>Go Back</strong></Link>
                              </NavItem>
                  ) : (<>
                                   <NavItem onClick={this.handleClick}>
                                   <Link to="/" className={this.state.btn1 ? 'active' : ''}><strong>For management</strong></Link>
                                  </NavItem>
                                  <NavItem onClick={this.handleClick}>
                                    <Link className={this.state.btn1 ? '' : 'active'} to="/signature-1"><strong>For agents</strong></Link>
                                  </NavItem>
                  </>)}
               </Nav>
            </Navbar>
        </div>
        )
    }
}