import React from 'react';
import ModalDisplay from './ModalDisplay'
import SignatureDisplay from './SignatureDisplay'
import { Card, CardBody} from 'reactstrap';
const SignatureContainer= (props) =>{
    console.log(props.list)
    return(
            <div >
                
                <Card  className="emailcard mb-2 mt-xs-5 ">
                    <CardBody className=" rounded p-0">
                            <div className="d-flex p-2 newmessagebar" >
                                <div className="pl-2 mr-auto text-white"><strong>New Message THREE</strong></div>
                                <div className="text-white"><img src={require('../../../img/x.svg')} /></div>
                            </div>
                            <div className="mb-2 pl-2 pt-2 text-muted border-muted border-bottom">To:</div>
                            <div className="border-muted pt-1 pl-2 mb-3 text-muted border-bottom">Subject:</div>
                            <p className='px-2 font-italic mt-4'>Dear Mr. User</p>
                            <p className='px-2 font-italic'><strong>This is the preview of your real signature.</strong><br/>
                            Fill up your details and signature design in the column on the left.</p>
                            <p className='px-2 font-italic mb-3'>Best Regards,<br/> Ron Fybish</p>
                            {
                            props.isChange?
                                <div className="px-2"><SignatureDisplay list={props.list} /><br/></div>
                                :
                               
                                    <table style={{width: "600px", fontSize: "11pt", fontFamily: "Arial, sans-serif"}} cellSpacing="0" cellPadding="0" border="0">
<tbody>
 <tr>
    <td style={{width:"104px", paddingTop: "3px", verticalAlign: "top" }} width="104">
        <a href="https://helpware.com" rel="noopener noreferrer" target="_blank">
            <img src={require('../../../img/firsttemplate/hero.png')} alt="Banner" style={{width:"120px", height: "auto", border: 0}} width="120" border="0"/>
        </a>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                								
										<a href="https://myglo.com.ua/products/kent-neostiks" rel="noopener noreferrer" target="_blank" style={{ 
                                            marginTop: "20px",
                                            backgroundColor: "#47C2B4", 
                                            borderRadius: "20px",
                                            color: "#ffffff",
                                            display: "inline-block",
                                            fontFamily: "sans-serif",
                                            fontSize: "13px",
                                            fontWeight: "bold",
                                            lineHeight: "40px",
                                            textAlign: "center",
                                            textDecoration: "none",
                                            width: "120px"
                                            }}>book a meeting</a>
										
                            </td>
                        </tr>
                    </tbody>
                </table>

    </td>
 
    <td style={{width:"410px"}} width="410">

        
        <table style={{width: "410px"}} cellSpacing="0" cellPadding="0">
            <tbody>
            <tr>

                <td style={{verticalAlign: "top", width: "260px", borderRight: "1px solid #47C2B4",fontSize: "10pt", lineHeight: "15px",paddingRight: "16px" }}>
                    <span style={{fontFamily: "Arial, sans-serif", color: "#373838", fontWeight: "bold", fontSize: "12pt", lineHeight: "23px"}}>Jeremy Ogle
            
                    </span>
                    <br/><br/>
                    <span style={{fontFamily: "Arial, sans-serif", color:"#47C2B4", fontWeight: "bold", fontSize: "12pt", lineHeight: "23px"}}>Your job title
                        
                    </span>
                    <br/>
                    
                </td>
                <td style={{width: "40px",height: "45px" }}></td>
                <td style={{width: "190px", fontSize: "10pt", lineHeight: "15px", verticalAlign: "top"}}>
                    <a href="https://helpware.com"><img src={require('../../../img/firsttemplate/helpware_logo_color-01 3.png')} alt="Banner" style={{width:"87px", height:"auto", border:"0" }} width="120" border="0"/></a>
                    <br/><br/>
                    <table>
                        <tr>
                            <td style={{paddingBottom: "10px" }}>
                                <span style={{fontSize: "10pt", lineHeight: "15px", fontFamily: "Arial, sans-serif", color: "#373838",padding: "0px", margin: "0px" }}>+1 (949) 273 - 2824<br/></span>
                            </td>
                        </tr>
                        <tr><td style={{paddingBottom: "10px"}}><span style={{fontSize: "10pt", lineHeight: "15px", fontFamily: "Arial, sans-serif", color: "#373838", padding: "0px", margin: "0px" }}>alexei@helpware.com <br/></span></td></tr>
                        <tr><td style={{paddingBottom: "10px"}}><span style={{fontSize: "10pt", lineHeight: "15px", fontFamily: "Arial, sans-serif", color: "#373838", padding: "0px", margin: "0px" }}>www.helpware.com</span></td></tr>
               
                    
                </table>
                </td>                
            </tr>
            <tr>
                <td style={{borderRight: "1px solid #47C2B4", paddingTop: "13px",paddingRight: "30px" }}>
                    <p style={{marginBottom: "0px", marginTop: 0, textAlign: "right" }}><span style={{display:"inline-block", height:"17px"}}><span><a href="https://www.facebook.com/helpware.io" rel="noopener noreferrer" target="_blank"><img alt="Facebook icon" style={{border:0, height:"17px", width:"17px"}} src={require('../../../img/firsttemplate/facebook.png')} width="17" height="17" border="0"/></a>&nbsp;&nbsp;</span><span><a href="https://www.linkedin.com/company/helpware/" rel="noopener noreferrer" target="_blank"><img alt="LinkedIn icon" style={{border:0, height:"17px", width:"17px"}} src={require('../../../img/firsttemplate/linkedin.png')} width="17" height="17" border="0"/></a>&nbsp;&nbsp;</span><span><a href="https://www.glassdoor.com/Reviews/Helpware-Reviews-E2089241.htm" rel="noopener noreferrer" target="_blank"><img alt="Twitter icon" style={{ border:0, height:"17px", width:"17px"}} src={require('../../../img/firsttemplate/glassdoor.png')} width="17" height="17" border="0"/></a></span></span></p>
                </td>
                <td></td>
                <td style={{paddingTop: "13px"}}>
                    <p style={{marginBottom: "0px"}}><span style={{display:"inline-block", height:"24px"}}><span><a href="https://clutch.co/profile/helpware#summary" rel="noopener noreferrer" target="_blank"><img alt="Facebook icon" style={{border:0, height:"24px", width:"24px"}} src={require('../../../img/firsttemplate/clutch.png')} width="24" height="24" border="0"/></a>&nbsp;&nbsp;</span><span><a href="https://upfirms.com/company/us/helpware/" rel="noopener noreferrer" target="_blank"><img alt="LinkedIn icon" style={{border:0, height:"24px", width:"24px"}} src={require('../../../img/firsttemplate/upfirm.png')} width="24" height="24" border="0"/></a>&nbsp;&nbsp;</span><span><a href="https://www.goodfirms.co/company/helpware" rel="noopener noreferrer" target="_blank"><img alt="Twitter icon" style={{border:0, height:"24px", width:"24px"}} src={require('../../../img/firsttemplate/goodfirms.png')} width="24" height="24" border="0"/></a>&nbsp;&nbsp;</span><span><a href="https://www.helpware.com/newsroom/top-business-service-provider-of-2019" rel="noopener noreferrer" target="_blank"><img alt="Youtube icon" style={{border:0, height:"24px", width:"24px"}} src={require('../../../img/firsttemplate/crowd.png')} width="24" height="24" border="0"/></a>&nbsp;&nbsp;</span><span><a href="https://themanifest.com/answering-services/financial/companies" rel="noopener noreferrer" target="_blank"><img alt="Instagram icon" style={{border:0, height:"24px", width:"32px" }} src={require('../../../img/firsttemplate/manifest.png')} width="32" height="24" border="0"/></a>&nbsp;&nbsp;</span></span></p>
                </td>
            </tr>
          
          
        </tbody></table>
    </td>
 </tr> 
 
</tbody>
</table>
                               
                            }
                    </CardBody>
                </Card>
                    <ModalDisplay list={props.list} isChange={props.isChange}   toggle={props.toggle} isOpen={props.isOpen}/>
            </div>
    )
}

export default SignatureContainer;

