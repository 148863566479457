import React, { Component } from 'react'
// import ModalSignature from './ModalSignature'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,Alert, } from 'reactstrap';
import SignatureDisplay from  './SignatureDisplay'
import {Link} from 'react-router-dom'
import HWAlert from '../HWAlert';

class ModalDisplay extends Component {
    constructor(props){
        super(props)

        this.state={
            isCopied: false,
            isCopiedCode: false,
        }
    }
    handleIsCopied=()=>{
        this.setState({isCopied:true, isCopiedCode:false})
    }
    handleCopy = event => {
        const copyBoxElement =this.copyBoxRef.current;
        copyBoxElement.contentEditable = true;
        copyBoxElement.focus();
        document.execCommand('selectAll');
        document.execCommand('copy');
        // console.log(document.execCommand('paste'));
        copyBoxElement.contentEditable = false;
        this.handleIsCopied();
        getSelection().empty();
        };

        copyTable = () => {
            const elTable = document.querySelector('.tablecode');
            console.log(elTable.innerHTML)
            let range, sel;
            const textarea = document.getElementById('message');
            textarea.value += elTable.innerHTML
            // Ensure that range and selection are supported by the browsers
            if (document.createRange && window.getSelection) {
            
              range = document.createRange();
              sel = window.getSelection();
              // unselect any element in the page
              sel.removeAllRanges();
            
              try {
                range.selectNodeContents(elTable);
                sel.addRange(range);
              } catch (e) {
                range.selectNode(elTable);
                sel.addRange(range);
              }
            
              document.execCommand('copy');
            }
            
            sel.removeAllRanges();

            let textarea2 = document.getElementById("message");
            textarea2.select();
            document.execCommand("copy");

            this.setState({isCopiedCode:true,isCopied:false})
            
            console.log('Element Copied! Paste it in a file1111')

            textarea.value = '';
            }

         selectElementContents = () => {
                var body = document.body, range, sel, el;
                el = document.querySelector('.tablecode');
                if (document.createRange && window.getSelection) {
                    range = document.createRange();
                    sel = window.getSelection();
                    sel.removeAllRanges();
                    try {
                        range.selectNodeContents(el);
                        sel.addRange(range);
                    } catch (e) {
                        range.selectNode(el);
                        sel.addRange(range);
                    }
                } else if (body.createTextRange) {
                    range = body.createTextRange();
                    range.moveToElementText(el);
                    range.select();
                    console.log(1111)
                }
            }

    copyBoxRef = React.createRef();
    render(){
        return(
            <div>
                <Button style={{background:"#47c2b4", margin: '40px auto'}} className="mb-5 mt-5 btn-block rounded-pill generatebtn" onClick={this.props.toggle}>Generate Signature</Button>
                    <Modal  isOpen={this.props.isOpen} toggle={this.props.toggle} >
                    <ModalHeader toggle={this.props.toggle} className="p-2 m-0 ml-2" ></ModalHeader>
                            {(this.state.isCopied && !this.state.isCopiedCode)?
                                    <Alert color="success">
                                    <h4 className="alert-heading">Copied!</h4>
                                    <p >all that’s left is to embed it in your email!</p>
                                    <hr />
                                    <Link to="/guide"><Button className="rounded-pill ml-3">How To Add Signature To Email ?</Button></Link>
                                </Alert>
                                :
                                null
                            }
                            {(this.state.isCopiedCode && !this.state.isCopied)?
                                    <Alert color="success">
                                    <h4 className="alert-heading">Copied!</h4>
                                    <p >all that’s left is to embed it in your hubspot!</p>
                                    <hr />
                                    <Link to="/guide"><Button className="rounded-pill ml-3">How To Add Signature To Email ?</Button></Link>
                                </Alert>
                                :
                                null
                            }
                        <ModalBody className="mx-auto">
                            {
                                this.props.isChange?
                                <div className="copyBox" ref={this.copyBoxRef}>
                                    <SignatureDisplay list={this.props.list} />
                                </div>
                                :
                                <HWAlert/>
                            }

                        </ModalBody>
                        <ModalFooter >
                            {
                            this.props.isChange?
                            <div className='mx-auto'>
                                <Button className="generatebtn rounded-pill pl-5 pr-5" style={{background:"#47c2b4"}} onClick={(event)=>this.handleCopy(event)}>Copy Signature</Button>{' '}
                                <Button className="ml-4 generatebtn rounded-pill pl-5 pr-5" style={{background:"#47c2b4"}} onClick={()=>{this.copyTable()}}>Get HTML Code</Button>{' '}
                            </div>
                            :
                            <div className='mx-auto'>
                                <Button className="rounded-pill pl-5 pr-5 generatebtn" style={{background:"#47c2b4"}} onClick={this.props.toggle} >Start Edit</Button>
                            </div>

                        }

                        <textarea id="message"></textarea>
                        </ModalFooter>
                    </Modal>                   
            </div>
        )

 }    
}

export default ModalDisplay;

