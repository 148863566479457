import React from 'react'

export default function HWAlert() {
  return (
    <div>
        <div className='alertwrapper'>
            <div className='alertimagewrapper'>
                <img src={require('../../img/xicon.svg')} alt='alert' />    
            </div>
            <div className='alertcontent'>
                <h5>To See your signature preview <strong>Start Editing Your Details!</strong></h5>
                <ul>  
                    <li>Edit your details,</li>
                    <li>Upload profile Image,</li>
                    <li>Add Linkedin and Zoom accounts (if needed).</li>
                </ul>
                <h5><strong>Good luck :)</strong></h5>
            </div>
        </div>
    </div>
  )
}
