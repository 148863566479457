import React, { Component } from 'react'
import {  Container, Row, Col } from 'reactstrap';

export default class AppJumbotron extends Component {
    render() {
        return (
            <div>
              <Container className="customized_container">
                
                <Row>
                  <Col lg={6} md={12} className="d-flex flex-column align-items-center justify-content-center">
                    <span className="suptitle ">Customization made simple 
</span>
                    <h1 className="display-4 text-left" style={{color:"#373838"}}>Create your email signature</h1>
                  <p className="lead clearfix text-left pt-3">Now, with a few simple steps, you can create a signature that reflects your personality and meets your needs and add it to your email or Hubspot account. This tool is user-friendly, convenient, and intuitive - just follow detailed guidelines to represent yourself online. 
                </p>
                <p className='text-center'>
                
                </p>
                  </Col>
                  <Col lg={6} md={12}>
                    <img src={require('../img/apphero.png')}/>
                  </Col>
                </Row>
              </Container>
          </div>
        )
    }
}