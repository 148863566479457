import React from 'react'



const SignatureDisplay = (props)=>{
    const {content,social,images,style}=props.list;
    const logo=content[5];
    const profile=images[1]

    const mainStyle={
        borderCollapse:"separate",
        border: "1px solid",
        borderRadius:style[2].userInput+"px",
        background:style[0].userInput,
        color:style[1].userInput,
        fontSize:'14px',
        paddingTop:"6px",
        paddingBottom:"6px",
        paddingLeft:"15px",
        paddingRight:"60px"
    }

    const contentStyle={
        paddingTop:style[3].userInput+"px",
        marginLeft:"10px"
    }
    const logoStyle={
        // width:style[4].userInput+"px",
        // height:style[4].userInput+"px",
        // borderRadius:style[5].userInput+"px",
        // marginBottom:"10px"
        borderRadius: "10px"
    }
    const profileStyle={
        width:style[6].userInput+"px",
        height:style[6].userInput+"px",
        borderRadius:style[7].userInput+"px",
        marginLeft:"10px"
        
    }
    const socialStyle={
        width:style[8].userInput+"px",
        height:style[8].userInput+"px",
        borderRadius:style[9].userInput+"px",
        marginLeft:"1px",
        marginRight:"1px",
       
    };

    
      console.log('hi', content[0].userInput)
    
    return(
            <div onClick={props.onClick} >
              <table style={{width: "600px", fontSize: "11pt", fontFamily: "Arial, sans-serif"}} cellSpacing="0" cellPadding="0" border="0">
<tbody>
 <tr>
    <td style={{width:"104px", paddingTop: "3px", verticalAlign: "top" }} width="104">
        <a href="https://helpware.com" rel="noopener noreferrer" target="_blank">
            <img src={logo.userInput || require('../../../img/firsttemplate/hero.png')} alt="Banner" style={{width:"120px", height: "auto", border: 0, ...logoStyle}} width="120" border="0"/>
        </a>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                								
										<a href={content[4].userInput} rel="noopener noreferrer" target="_blank" style={{ 
                                            marginTop: "20px",
                                            backgroundColor: "#47C2B4", 
                                            borderRadius: "20px",
                                            color: "#ffffff",
                                            display: "inline-block",
                                            fontFamily: "sans-serif",
                                            fontSize: "13px",
                                            fontWeight: "bold",
                                            lineHeight: "40px",
                                            textAlign: "center",
                                            textDecoration: "none",
                                            width: "120px"
                                            }}>book a meeting</a>
										
                            </td>
                        </tr>
                    </tbody>
                </table>

    </td>
 
    <td style={{width:"410px"}} width="410">

        
        <table style={{width: "410px"}} cellSpacing="0" cellPadding="0">
            <tbody>
            <tr>

                <td style={{verticalAlign: "top", width: "260px", borderRight: "1px solid #47C2B4",fontSize: "10pt", lineHeight: "15px",paddingRight: "16px" }}>
                    <span style={{fontFamily: "Arial, sans-serif", color: "#373838", fontWeight: "bold", fontSize: "12pt", lineHeight: "23px"}}> { content[0].userInput || "Jeremy Ogle" }
            
                    </span>
                    <br/><br/>
                    <span style={{fontFamily: "Arial, sans-serif", color:"#47C2B4", fontWeight: "bold", fontSize: "12pt", lineHeight: "23px"}}>{ content[1].userInput || "Your job title" }
                        
                    </span>
                    <br/>
                    
                </td>
                <td style={{width: "40px",height: "45px" }}></td>
                <td style={{width: "190px", fontSize: "10pt", lineHeight: "15px", verticalAlign: "top"}}>
                    <a href="https://helpware.com"><img src={profile.userInput || require('../../../img/firsttemplate/helpware_logo_color-01 3.png')} alt="Banner" style={{width:"87px", height:"auto", border:"0" }} width="120" border="0"/></a>
                    <br/><br/>
                    <table>
                        <tr>
                            <td style={{paddingBottom: "10px" }}>
                                <span style={{fontSize: "10pt", lineHeight: "15px", fontFamily: "Arial, sans-serif", color: "#373838",padding: "0px", margin: "0px" }}>{ content[3].userInput || "+1 (949) 273 - 2824"}<br/></span>
                            </td>
                        </tr>
                        <tr><td style={{paddingBottom: "10px"}}><span style={{fontSize: "10pt", lineHeight: "15px", fontFamily: "Arial, sans-serif", color: "#373838", padding: "0px", margin: "0px" }}>{ content[2].userInput || "alexei@helpware.com"}<br/></span></td></tr>
                        <tr><td style={{paddingBottom: "10px"}}><span style={{fontSize: "10pt", lineHeight: "15px", fontFamily: "Arial, sans-serif", color: "#373838", padding: "0px", margin: "0px" }}>{content[5].userInput || "www.helpware.com"}</span></td></tr>
               
                    
                </table>
                </td>                
            </tr>
            <tr>
                <td style={{borderRight: "1px solid #47C2B4", paddingTop: "13px",paddingRight: "30px" }}>
                    <p style={{marginBottom: "0px", marginTop: 0, textAlign: "right" }}><span style={{display:"inline-block", height:"17px"}}><span><a href={social[0].userInput || 'https://www.facebook.com/helpware.io'} rel="noopener noreferrer" target="_blank"><img alt="Facebook icon" style={{border:0, height:"17px", width:"17px"}} src={require('../../../img/firsttemplate/facebook.png')} width="17" height="17" border="0"/></a>&nbsp;&nbsp;</span><span><a href={social[3].userInput || 'https://www.linkedin.com/company/helpware/'} rel="noopener noreferrer" target="_blank"><img alt="LinkedIn icon" style={{border:0, height:"17px", width:"17px"}} src={require('../../../img/firsttemplate/linkedin.png')} width="17" height="17" border="0"/></a>&nbsp;&nbsp;</span><span><a href={social[2].userInput || 'https://www.glassdoor.com/Reviews/Helpware-Reviews-E2089241.htm'} rel="noopener noreferrer" target="_blank"><img alt="Twitter icon" style={{ border:0, height:"17px", width:"17px"}} src={require('../../../img/firsttemplate/glassdoor.png')} width="17" height="17" border="0"/></a></span></span></p>
                </td>
                <td></td>
                <td style={{paddingTop: "13px"}}>
                    <p style={{marginBottom: "0px"}}><span style={{display:"inline-block", height:"24px"}}><span><a href="https://clutch.co/profile/helpware#summary" rel="noopener noreferrer" target="_blank"><img alt="Facebook icon" style={{border:0, height:"24px", width:"24px"}} src={require('../../../img/firsttemplate/clutch.png')} width="24" height="24" border="0"/></a>&nbsp;&nbsp;</span><span><a href="https://upfirms.com/company/us/helpware/" rel="noopener noreferrer" target="_blank"><img alt="LinkedIn icon" style={{border:0, height:"24px", width:"24px"}} src={require('../../../img/firsttemplate/upfirm.png')} width="24" height="24" border="0"/></a>&nbsp;&nbsp;</span><span><a href="https://www.goodfirms.co/company/helpware" rel="noopener noreferrer" target="_blank"><img alt="Twitter icon" style={{border:0, height:"24px", width:"24px"}} src={require('../../../img/firsttemplate/goodfirms.png')} width="24" height="24" border="0"/></a>&nbsp;&nbsp;</span><span><a href="https://www.helpware.com/newsroom/top-business-service-provider-of-2019" rel="noopener noreferrer" target="_blank"><img alt="Youtube icon" style={{border:0, height:"24px", width:"24px"}} src={require('../../../img/firsttemplate/crowd.png')} width="24" height="24" border="0"/></a>&nbsp;&nbsp;</span><span><a href="https://themanifest.com/answering-services/financial/companies" rel="noopener noreferrer" target="_blank"><img alt="Instagram icon" style={{border:0, height:"24px", width:"32px" }} src={require('../../../img/firsttemplate/manifest.png')} width="32" height="24" border="0"/></a>&nbsp;&nbsp;</span></span></p>
                </td>
            </tr>
          
          
        </tbody></table>
    </td>
 </tr> 
 
</tbody>
</table>
        </div>
    )
}

export default SignatureDisplay;



// const ModalSignature = (props)=>{const {content,social,images,style}=props.list;const logo=images[0];const profile=images[1];const mainStyle={backgroundColor:style[0].userInput,color:style[1].userInput,borderRadius:style[2].userInput+"px",fontSize:'16px',};const contentStyle={paddingTop:style[3].userInput+"px",marginLeft:"7px"};const logoStyle={width:style[4].userInput+"px",height:style[4].userInput+"px",borderRadius:style[5].userInput+"px",marginLeft:"4px",marginRight:"3px"};const profileStyle={width:style[6].userInput+"px",height:style[6].userInput+"px",borderRadius:style[7].userInput+"px",};const socialStyle={width:style[8].userInput+"px",height:style[8].userInput+"px",borderRadius:style[9].userInput+"px",marginLeft:"4px",marginRight:"3px"};return(<div style={mainStyle}><table><tr><td>{logo.userInput.length>0?<div><img alt="img-logo" style={logoStyle} src={logo.userInput}/></div>:null}{social.map((el,index)=>{if(el.userInput.length>0) return  <a key={index} target="_blank"  rel="noopener noreferrer" href={el.userInput}><img style={socialStyle} alt="img-social"  src={el.icon}/></a>;else return null;})}</td><td><div style={{borderLeftColor:style[1].userInput,borderLeftStyle:'solid',marginLeft:"4px",paddingLeft:"3px"}} >{profile.userInput.length>0?<div><img alt="img" style={profileStyle} src={profile.userInput}/></div>:null}{content.map((el,index)=>{if(el.length===0)return null;return <div style={contentStyle} key={index}>{el.userInput}</div>})}</div></td></tr></table></div>)}
// export default ModalSignature