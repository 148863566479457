import React, { useEffect } from 'react'



const SignatureDisplay = (props)=>{
    const {content,social,images,style}=props.list;
    const logo=content[6];
    const profile=images[1]

    const mainStyle={
        borderCollapse:"separate",
        border: "1px solid",
        borderRadius:style[2].userInput+"px",
        background:style[0].userInput,
        color:style[1].userInput,
        fontSize:'14px',
        paddingTop:"6px",
        paddingBottom:"6px",
        paddingLeft:"15px",
        paddingRight:"60px"
    }

    const tDecor = {
        textDecoration:'unset'
    }

    const contentStyle={
        paddingTop:style[3].userInput+"px",
        marginLeft:"10px"
    }
    const logoStyle={
        // width:style[4].userInput+"px",
        // height:style[4].userInput+"px",
        // borderRadius:style[5].userInput+"px",
        // marginBottom:"10px"
        borderRadius: "10px"
    }
    const profileStyle={
        width:style[6].userInput+"px",
        height:style[6].userInput+"px",
        borderRadius:style[7].userInput+"px",
        marginLeft:"10px"
        
    }
    const socialStyle={
        width:style[8].userInput+"px",
        height:style[8].userInput+"px",
        borderRadius:style[9].userInput+"px",
        marginLeft:"1px",
        marginRight:"1px",
       
    };
    

       
    return(
            <div className='tablecode' onClick={props.onClick} >
                    <table cellSpacing="0" cellPadding="0" border="0" style={{width: '600px', fontSize: '11pt', fontFamily: 'Arial, sans-serif' }}>
                               
                                <tbody>
                                    <tr  style={{width: '600px'}}>
                                        <td width="104" style={{width: '104px', paddingTop: '3px', verticalAlign: 'top'}}>
                                            <a href="https://helpware.com" rel="noopener noreferrer" target="_blank">
                                                <img src={logo.userInput || require('../../img/imgplaceholder.jpg')} alt="Banner" width="120" border="0" style={{width: '120px', height: 'auto', border:'0px', borderRadius: '10px'}}/>
                                            </a>
                                          
                                        </td>
                                        <td width="474" style={{width: '450px', paddingLeft: '20px'}}>
                                            <table cellSpacing="0" cellPadding="0" style={{width: '450px', verticalAlign: 'bottom'}}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{verticalAlign: 'top', width: '100%'}}>
                                                            <span style={{fontFamily: 'Arial, sans-serif', fontWeight: 'bold', fontSize: '12pt', lineHeight: '23px', color: '#373838'}}>{ content[0].userInput || "Jeremy Ogle" }</span>
                                                        </td>
                                                        <td align="right" style={{textAlign: 'right', width: '100%'}}>
                                                            <img style={{height: '24px', width: '88px'}} height="24" width="88" src="https://siggen-content.s3.amazonaws.com/signatureicons/logoupscaled.png" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{paddingBottom: '7px', width: '100%'}}>
                                                            <span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(71, 194, 180)', width: '100%', fontWeight: 'bold', fontSize: '12pt', lineHeight: '23px', paddingTop: 0, display: 'inline-block' }}>{ content[1].userInput || "Your job title" }
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                        
                                            <table cellSpacing="0" cellPadding="0" style={{width: '450px', borderTop: '2px solid #47c2b4'}}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ paddingTop: '10px' }}>
                                                            <p style={{marginTop: 0, marginBottom: 7 }}>
                                                                <span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(71, 194, 180)', fontWeight: 'bold', fontSize: '12pt', lineHeight: '23px'}}>W&nbsp;&nbsp;</span>
                                                                <a href="https://helpware.com" rel="noopener noreferrer" target="_blank" style={{textDecoration: 'unset', fontFamily: 'Arial, sans-serif',fontSize: '11pt', lineHeight: '23px', color: '#373838', display: 'inline-block', ...tDecor}}><span style={{ textDecoration: 'unset', }}>www.helpware.com</span></a>
                                                            </p>
                                                        </td>
                                                        <td>&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p style={{ marginBottom: 7, marginTop: 0 }}>
                                                                <span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(71, 194, 180)', fontWeight: 'bold', fontSize: '12pt', lineHeight: '23px'}}>E&nbsp;&nbsp;&nbsp;</span>
                                                                <a href={content[2].userInput ? 'mailto:' + content[2].userInput : 'mailto:hello@helpware.com'} style={{textDecoration: 'unset', fontFamily: 'Arial, sans-serif',fontSize: '11pt', lineHeight: '23px', color: '#373838', ...tDecor}}><span style={{ textDecoration: 'none'}}>{content[2].userInput || 'hello@helpware.com'} </span></a>
                                                            </p>
                                                        </td>
                                                        <td style={{width: '100%', textAlign: 'right'}} align="right">
                                                          
                                                                    
                                                        <p style={{textAlign: 'right', marginBottom: 7, marginTop: 0}}>
                                                                            <span style={{fontFamily: 'Arial, sans-serif', color: 'rgb(71, 194, 180)', fontWeight: 'bold', fontSize: '12pt', lineHeight: '23px'}}>T&nbsp;&nbsp;</span>
                                                                            <a rel="noopener noreferrer" target="_blank" href={ `tel:${content[3].userInput || "+1 (949) 273 - 2824"} `} style={{textDecoration: 'unset', fontFamily: 'Arial, sans-serif',fontSize: '11pt', lineHeight: '23px', color: '#373838', ...tDecor}}>{ content[3].userInput || "+1 (949) 273 - 2824"}</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <a href={content[5].userInput || 'https://www.linkedin.com/company/helpware/'} rel="noopener noreferrer" target="_blank"  style={{ ...tDecor,  textDecoration: 'unset'}}><img style={{height: '17px', width: '17px'}} height="17" width="17" src="https://3358429.fs1.hubspotusercontent-na1.net/hubfs/3358429/linkedin.png" alt="linkedin" /></a>
                                                                        </p>
                                                                     
                                                                    
                                                                
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{paddingTop: 10}}>
                                        <a href={content[4].userInput || '#'} rel="noopener noreferrer" target="_blank" style={{marginTop: 0,  display: 'inline-block', fontFamily: 'sans-serif', fontSize: '13px', fontWeight: 'bold', lineHeight: '40px', textAlign: 'center', textDecoration: 'unset', width: '120px', ...tDecor}}><img width="120" style={{ width: '120px'}} src="https://3358429.fs1.hubspotusercontent-na1.net/hubfs/3358429/CTA_button%20(1).png" /></a>
                                        </td>
                                        <td style={{paddingTop: 10}}>
                                            <p style={{marginBottom: '0px', textAlign: 'right', paddingTop: 0 }}><span style={{display: 'inline-block', height: '24px'}}><span><a href="https://clutch.co/profile/helpware#summary" rel="noopener noreferrer" target="_blank"><img alt="Facebook icon" src="https://siggen-content.s3.amazonaws.com/signatureicons/clutch.png" border="0" style={{border: '0px', height: '15px'}} height="15"/></a>&nbsp;&nbsp;</span><span><a href="https://www.g2.com/products/helpware/reviews" rel="noopener noreferrer" target="_blank"><img alt="LinkedIn icon" src="https://siggen-content.s3.amazonaws.com/signatureicons/upscaled.png" border="0" style={{border: '0px', height: '15px'}} height="15"/></a>&nbsp;&nbsp;</span><span><a href="https://www.goodfirms.co/company/helpware" rel="noopener noreferrer" target="_blank"><img alt="Twitter icon" src="https://siggen-content.s3.amazonaws.com/signatureicons/union.png" border="0" style={{border: '0px', height: '15px'}} height="15"/></a>&nbsp;&nbsp;</span><span><a href="https://upcity.com/profiles/helpware-io" rel="noopener noreferrer" target="_blank"><img alt="Youtube icon" src="https://siggen-content.s3.amazonaws.com/signatureicons/upcity.png" border="0" style={{border: '0px', height: '15px'}} height="15"/></a>&nbsp;&nbsp;</span><span><a href="https://upfirms.com/company/us/helpware/" rel="noopener noreferrer" target="_blank"><img alt="Instagram icon" src="https://siggen-content.s3.amazonaws.com/signatureicons/upfirms.png" border="0" style={{border: '0px', height: '15px'}} height="15"/></a>&nbsp;&nbsp;</span><span><a href="https://www.glassdoor.com/Reviews/Helpware-Reviews-E2089241.htm" rel="noopener noreferrer" target="_blank"><img alt="Instagram icon" src="https://siggen-content.s3.amazonaws.com/signatureicons/glassdoor.png" border="0" style={{border: '0px', height: '15px'}} height="15"/></a>&nbsp;&nbsp;</span></span></p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table> 
        </div>
    )
}

export default SignatureDisplay;



// const ModalSignature = (props)=>{const {content,social,images,style}=props.list;const logo=images[0];const profile=images[1];const mainStyle={backgroundColor:style[0].userInput,color:style[1].userInput,borderRadius:style[2].userInput+"px",fontSize:'16px',};const contentStyle={paddingTop:style[3].userInput+"px",marginLeft:"7px"};const logoStyle={width:style[4].userInput+"px",height:style[4].userInput+"px",borderRadius:style[5].userInput+"px",marginLeft:"4px",marginRight:"3px"};const profileStyle={width:style[6].userInput+"px",height:style[6].userInput+"px",borderRadius:style[7].userInput+"px",};const socialStyle={width:style[8].userInput+"px",height:style[8].userInput+"px",borderRadius:style[9].userInput+"px",marginLeft:"4px",marginRight:"3px"};return(<div style={mainStyle}><table><tr><td>{logo.userInput.length>0?<div><img alt="img-logo" style={logoStyle} src={logo.userInput}/></div>:null}{social.map((el,index)=>{if(el.userInput.length>0) return  <a key={index} target="_blank"  rel="noopener noreferrer" href={el.userInput}><img style={socialStyle} alt="img-social"  src={el.icon}/></a>;else return null;})}</td><td><div style={{borderLeftColor:style[1].userInput,borderLeftStyle:'solid',marginLeft:"4px",paddingLeft:"3px"}} >{profile.userInput.length>0?<div><img alt="img" style={profileStyle} src={profile.userInput}/></div>:null}{content.map((el,index)=>{if(el.length===0)return null;return <div style={contentStyle} key={index}>{el.userInput}</div>})}</div></td></tr></table></div>)}
// export default ModalSignature